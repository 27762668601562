$('#login-form').find('input,select').not("[type=submit]").jqBootstrapValidation({
    preventSubmit: true,
    submitSuccess: function ($form, event) {
        event.preventDefault();
        var formData = {
            'email': $('input[name=email]').val(),
            'password': $('input[name=password]').val()
        };
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            dataType: 'json',
            beforeSend: function () {
                showLoadingScreen();
            }
        })
        .done(function (response) {
            $.unblockUI();
            messageReturn(response.message, response.type_message, response.url);
        })
        .fail(function (jqXHR, textStatus, msg) {
                //alert(msg);
        });
    }
});

$('#forgot-form').find('input,select,textarea').not("[type=submit]").jqBootstrapValidation({
    preventSubmit: true,
    submitSuccess: function ($form, event) {
        event.preventDefault();
        var formData = {
            'email': $('input[name=email]').val()
        };
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            dataType: 'json',
            beforeSend: function () {
                showLoadingScreen();
            }
        })
        .done(function (response) {
            messageReturn(response.message, response.type_message, response.url);
        })
        .fail(function (jqXHR, textStatus, msg) {
                //alert(msg);
        });
    }
});

$('#new-password').find('input,select,textarea').not("[type=submit]").jqBootstrapValidation({
    preventSubmit: true,
    submitSuccess: function ($form, event) {
        event.preventDefault();
        var formData = {
            'password': $('input[name=password]').val(),
            'token': $('input[name=token]').val()
        };
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            dataType: 'json',
            beforeSend: function () {
                showLoadingScreen();
            }
        })
        .done(function (response) {
            $.unblockUI();
            messageReturn(response.message, response.type_message, response.url);
        })
        .fail(function (jqXHR, textStatus, msg) {
                //alert(msg);
        });
    }
});